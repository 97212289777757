/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://jm2iy68hck.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        },
        {
            "name": "FileUploadApi",
            "endpoint": "https://e9mqd2or33.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://tdsvb6iemjf7vdroyqyjnkkp64.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ilkmclno6jgvvntskolw2waw5q",
    "aws_cognito_identity_pool_id": "us-east-1:e0d1c077-e618-4f00-ac59-dd63dd0f9d67",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_EhGZA2Q6W",
    "aws_user_pools_web_client_id": "4omnna1bjlhce60pl4mmfmveiq",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "coiguard-storage-68bff28f172617-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
